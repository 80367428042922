import { FC, useEffect, useState } from 'react'
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
  Stack,
  Table,
} from 'react-bootstrap'
import { IconContext } from 'react-icons'
import {
  BsCheckCircleFill,
  BsCloudArrowUpFill,
  BsExclamationTriangleFill,
  BsFileEarmarkExcel,
  BsFillPencilFill,
  BsSearch,
  BsSignMergeLeft,
  BsXCircleFill,
} from 'react-icons/bs'
import { FaPeopleArrows, FaWarehouse } from 'react-icons/fa'
import I1868FacturaDetalle from '../../Interfaces/I1868FacturaDetalle'
import DTO1868Factura from '../../DTO/DTO1868Factura'
import DTO1868ReporteFactura from '../../DTO/DTO1868ReporteFactura'
import DTO1868Clientes from '../../DTO/DTO1868Clientes'
import FService from '../../Services/Facturas.Services'
import DSCatDestinos from '../../Services/CatDestinos.Services'
import { Cat1868Destinos } from '../../Catalogos/CatDestinos'
import Cat1868Responsables from '../../Catalogos/CatResponsables'
import { useDispatch, useSelector } from 'react-redux'
import '../../../../css/zincinternacional.css'
import '../../../../css/generic01.css'
import { Rpt1868FacturasDetalle } from './RptFacturasDetalle'
import { RootState } from '../../../../store/store'
import {
  populateFacturaDetalle,
  toggleRow,
} from '../../../../store/features/Clientes/1868/Data1868FacturaDetalleSlice'
import { populateCatClaveSAT } from '../../../../store/features/Clientes/1868/Cat1868ClaveSATSlice'
import { populateCatDestinos } from '../../../../store/features/Clientes/1868/Cat1868DestinosSlice'
import { populateCatResponsables } from '../../../../store/features/Clientes/1868/Cat1868ResponsablesSlice'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import FileManagerDS from '../../../../Services/Utils/FileManager.Services'
import xlsx from 'json-as-xlsx'
import 'use-context-menu/styles.css'
import FileManager from '../../../Utils/FileManager/FileManager'
import InputMask from 'react-input-mask'
import ICatBroker from '../../Interfaces/ICatBroker'
import CatBrokerDS from '../../Services/CatBrokers.Services'
import ReactToggleSliderSwitch from 'react-toggle-slider-switch'

interface IProps {}

export default function Rpt1868Facturas(props: IProps) {
  const dispatch = useDispatch()
  const mCatDestinos = useSelector(
    (state: RootState) => state.Cat1868Destinos.Cat1868Destinos
  )
  const mCatResponsables = useSelector(
    (state: RootState) => state.Cat1868Responsables.Cat1868Responsables
  )
  const mDataFacturaDetalle = useSelector(
    (state: RootState) => state.Data1868FacturaDetalle.Data1868FacturaDetalle
  )
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [UserType, setUserType] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserType')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [CurrentTime2Number, setCurrentTime2Number] = useState(0)
  const [Cliente, setCliente] = useState('')
  const [Clientes, setClientes] = useState<DTO1868Clientes[]>([])
  const [Data, setData] = useState<DTO1868ReporteFactura[]>([])
  const [DataDetalle, setDataDetalle] = useState<I1868FacturaDetalle[]>([])
  const [DataOriginal, setDataOriginal] = useState<DTO1868ReporteFactura[]>([])
  const [ID, setID] = useState(0)
  const [Invoice, setInvoice] = useState('')
  const [Sellos, setSellos] = useState('')
  const [Placas, setPlacas] = useState('')
  const [Empaque, setEmpaque] = useState('')
  const [IDDestino, setIDDestino] = useState(0)
  const [IDResponsable, setIDResponsable] = useState(0)
  const [Responsable, setResponsable] = useState('')
  const [TipoViaje, setTipoViaje] = useState(1)
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [Inicio, setInicio] = useState(currentDate(-30))
  const [Fin, setFin] = useState(currentDate(0))
  const [filtro, setFiltro] = useState('')
  const [tipoReporte, setTipoReporte] = useState(0)
  const [msgColor, setMsgColor] = useState('primary')
  const [modalFileManagerXML, setModalFileManagerXML] = useState(false)
  const [modalFileManagerPDF, setModalFileManagerPDF] = useState(false)
  const [modalEditMaster, setModalEditMaster] = useState(false)
  const [modalDeleteMaster, setModalDeleteMaster] = useState(false)
  const [ModalCatalogos, setModalCatalogos] = useState(false)
  const [Proceso, setProceso] = useState(0)
  const [ModalFileManager, setModalFileManager] = useState(false)
  const [Entregar, setEntregar] = useState('')
  const [Catalogo, setCatalogo] = useState(0)
  const [Urgente, setUrgente] = useState(0)
  const [Pallets, setPallets] = useState(0)
  const [FecVigenciaDODA, setFecVigenciaDODA] = useState(
    getNextBusinessDay(new Date())
  )
  const [IdDODAFile, setIdDODAFile] = useState(0)
  const [Referencia, setReferencia] = useState('')
  const [Remesa, setRemesa] = useState('')
  const [PedimentoLargo, setPedimentoLargo] = useState('')
  const [IDTransfer, setIDTransfer] = useState(0)
  const [IdBroker, setIdBroker] = useState<number>(1)
  const [CatBrokers, setCatBrokers] = useState<ICatBroker[]>([])
  const [InBond, setInbond] = useState<boolean>(false)
  const [LblInBond, setLblInbond] = useState('Inbound NO')

  const handleChange = (newChecked: boolean): void => {
    setLblInbond(newChecked ? 'Inbound SI' : 'Inbound NO')
    setInbond(newChecked)
  }

  const EditMaster = (row: DTO1868ReporteFactura) => {
    console.log(row)
    setID(row.id)
    let Ubicacion = row.entregar ? row.entregar : ''
    Ubicacion = Ubicacion.replaceAll(' ', '')
      .replaceAll('\r', '')
      .replaceAll('\n', '')
    setSellos(row.sello)
    setPlacas(row.placas)
    setEntregar(row.entregar)
    setInvoice(row.invoice)
    setIDDestino(row.idDestino)
    setIDTransfer(row.idResponsableCruce)
    setResponsable(row.responsableCruce)
    setIDResponsable(row.idTransportistaViaje)
    setUrgente(row.urgente)
    setPallets(row.pallets)
    setTipoViaje(row.tipoViaje)
    setIdBroker(row.idBroker)
    setInbond(row.inBond)
    setModalEditMaster(true)
  }

  const showPDF = (id: number) => {
    FService.getFileContent(id, 8)
      .then((response: any) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('A esta factura no se le ha anexado PDF')
        setShowMsg(true)
        return
      })
  }

  const showXML = (id: number, Invoice: string) => {
    FService.getFileContent(id, 7)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${Invoice}.xml`)
          document.body.appendChild(link)
          link.click()
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('A esta factura no se le ha anexado XML')
        setShowMsg(true)
        return
      })
  }

  function exportExcel(
    jsonData: DTO1868ReporteFactura[],
    fileName: string
  ): void {
    let Heading = [
      [
        'Fecha factura',
        'Invoice',
        'No Caja',
        'Fecha cruce',
        'Cliente',
        'Pedido cliente',
        'Transportista',
        'Responsable cruce',
        'SCAC',
        'CAAT',
        'Sellos',
        'Placas',
        'Carga',
        'Tipo pedimento',
        'Entregar en',
        'Aduana',
      ],
    ]
    const dataOnly = jsonData.map(
      ({
        fechaFactura,
        invoice,
        ruta,
        fechaCruce,
        cliente,
        pedidoCliente,
        transportista,
        responsableCruce,
        scac,
        caat,
        sello,
        placas,
        carga,
        tipoPedimento,
        entregar,
        aduana,
      }) => {
        return {
          more: { fechaFactura },
          invoice,
          ruta,
          fcruce: { fechaCruce },
          cliente,
          pedidoCliente,
          transportista,
          responsableCruce,
          scac,
          caat,
          sello,
          placas,
          carga,
          tipoPedimento,
          entregar,
          aduana,
        }
      }
    )
    let data = [
      {
        sheet: 'Facturas',
        columns: [
          {
            label: 'Fecha factura',
            value: (row: any) => row?.more?.fechaFactura.substring(0, 10) ?? '',
          },
          { label: 'Factura', value: 'invoice' },
          { label: 'No Caja', value: 'ruta' },
          {
            label: 'Fecha cruce',
            value: (row: any) =>
              row.fcruce.fechaCruce
                ? row.fcruce.fechaCruce.substring(0, 10)
                : '',
          },
          { label: 'Cliente', value: 'cliente' },
          { label: 'Pedido cliente', value: 'pedidoCliente' },
          { label: 'Transportista', value: 'transportista' },
          { label: 'Responsable', value: 'responsableCruce' },
          { label: 'SCAC', value: 'scac' },
          { label: 'CAAT', value: 'caat' },
          { label: 'SELLO', value: 'sello' },
          { label: 'Placas', value: 'placas' },
          { label: 'Carga', value: 'carga' },
          { label: 'Tipo pedimento', value: 'tipoPedimento' },
          { label: 'Entregar', value: 'entregar' },
          { label: 'Aduana', value: 'aduana' },
        ],
        content: dataOnly,
      },
    ]
    let settings = {
      fileName: 'Reporte de facturas ',
      extraLength: 3,
      writeOptions: {},
      RTL: false,
    }
    xlsx(data, settings)
  }

  function currentDate(days: number): string {
    var today = new Date()
    today.setDate(today.getDate() + days)
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  function getNextBusinessDay(date: Date): string {
    date = new Date(+date)
    let newDate = new Date(+date)
    do {
      newDate.setDate(newDate.getDate() + 3)
    } while (!(newDate.getDay() % 6))
    if (DayDiff(date, newDate) === 6) {
      newDate.setDate(newDate.getDate() - 1)
    }
    var dd = String(newDate.getDate()).padStart(2, '0')
    var mm = String(newDate.getMonth() + 1).padStart(2, '0')
    var yyyy = newDate.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  function DayDiff(date1: Date, date2: Date): number {
    var Difference_In_Time = date2.getTime() - date1.getTime()
    return Difference_In_Time / (1000 * 3600 * 24)
  }

  const generaReporte = () => {
    setTipoReporte(0)
    FService.getAll(Inicio, Fin, UserId, Perfil, 1)
      .then((response) => {
        console.log(response.data)
        loadCustomers()
        loadCatDestinos()
        setData(response.data)
        setDataOriginal(response.data)
        FService.getAllDetail(Inicio, Fin)
          .then((responsed) => {
            setDataDetalle(responsed.data)
            dispatch(populateFacturaDetalle(responsed.data))
          })
          .catch((e: Error) => {
            setHeader('Error')
            setMsg('Ocurrio un error: ' + e)
            setShowMsg(true)
            return
          })
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const filtraReporte = (e: any) => {
    let value = e.target.value
    value = value.toUpperCase()
    setFiltro(value)
    if (value.length > 0) {
      setData(
        Data.filter(function (row: DTO1868ReporteFactura) {
          return (
            row.fechaFactura.includes(value) ||
            row.invoice.includes(value) ||
            row.ruta.includes(value) ||
            row.cliente.includes(value) ||
            row.pedidoCliente.includes(value) ||
            row.transportista.includes(value) ||
            row.responsableCruce.includes(value) ||
            row.carga.includes(value) ||
            row.tipoPedimento.includes(value) ||
            row.aduana.includes(value)
          )
        })
      )
    } else {
      setData(DataOriginal)
    }
  }

  const uploadXML = () => {
    const d1 = new Date()
    setCurrentTime2Number(d1.getTime())
    setModalFileManagerXML(true)
  }

  const uploadPDF = () => {
    const d1 = new Date()
    setCurrentTime2Number(d1.getTime())
    setModalFileManagerPDF(true)
  }

  const saveMaster = () => {
    const data: DTO1868Factura = {
      id: ID,
      sello: Sellos ? Sellos : '',
      placas: Placas ? Placas : '',
      idResponsable: IDResponsable ? IDResponsable : 0,
      idTransfer: IDTransfer ? IDTransfer : 0,
      responsable: Responsable ? Responsable : '',
      idDestino: IDDestino ? IDDestino : 0,
      entregar: Entregar ? Entregar : '',
      urgente: Urgente,
      pallets: Pallets,
      tipoViaje: TipoViaje,
      IdBroker: IdBroker,
      inBond: InBond,
    }
    FService.Update(data)
      .then((response) => {
        setHeader(response.data ? 'Informativo' : 'Error')
        setMsg(
          response.data
            ? 'La informacion se guardo correctamente'
            : 'Ocurrio un error, no se guardo la informacion'
        )
        const idx = Data.findIndex((obj) => obj.id == ID)
        Data[idx].sello = Sellos
        Data[idx].placas = Placas
        Data[idx].empaqueDescripcion = Empaque
        Data[idx].idResponsableCruce = IDResponsable
        Data[idx].responsableCruce = Responsable
        Data[idx].idDestino = IDDestino
        setDataOriginal(Data)
        setModalEditMaster(false)
        setShowMsg(true)
        generaReporte()
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const uploadFile = (idFile: number, Process: number) => {
    FService.Append(idFile, Process)
      .then((response) => {
        if (!response.data) {
          setHeader('Error')
          setMsg('Ocurrio un error ')
          setShowMsg(true)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCustomers = () => {
    setTipoReporte(0)
    FService.getClientes()
      .then((response) => {
        setClientes(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCatClaveSAT = () => {
    FService.getCatClaveSAT()
      .then((response) => {
        dispatch(populateCatClaveSAT(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCatDestinos = () => {
    DSCatDestinos.Get()
      .then((response) => {
        dispatch(populateCatDestinos(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCatResponsables = () => {
    FService.getCatResponsables()
      .then((response) => {
        dispatch(populateCatResponsables(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCatalog = (cat: number) => {
    setCatalogo(cat)
    setModalCatalogos(true)
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Reporte de Facturas de Zinc Internacional')
  }

  const uploadFileByProcess = (idFile: number, Process: number) => {
    FService.RelateByProcess(ID, idFile, Proceso)
      .then((response) => {
        if (!response.data) {
          setHeader('Error')
          setMsg('Ocurrio un error, no se guardo correctamente el archivo')
          setShowMsg(true)
        }
        generaReporte()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const UnRelateFileByProcess = (IDFactura: number, Process: number) => {
    FService.UnRelateFileByProcess(ID, Proceso)
      .then((response) => {
        if (!response.data) {
          setHeader('Error')
          setMsg('Ocurrio un error, no se elimino correctamente el archivo')
          setShowMsg(true)
        }
        generaReporte()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const updateDODADoDATE = (IdDODA: number) => {
    FService.UpdateVigenciaDODA(IdDODA, FecVigenciaDODA)
      .then((response) => {})
      .catch((e: Error) => {
        setHeader('Error')
        setMsg(
          'Ocurrio un error, no se actualizo la fecha de vigencia del DODA '
        )
        setShowMsg(true)
        return
      })
  }

  const updateReferenciaRemesa = (IdFactura: number) => {
    FService.UpdateReferenciaRemesa(
      IdFactura,
      Referencia ? Referencia : '.',
      Remesa ? Remesa : '.',
      PedimentoLargo
    )
      .then((response) => {
        generaReporte()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error, no se actualizo la referencia, remesa')
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    loadCustomers()
    loadCatClaveSAT()
    loadCatDestinos()
    loadCatResponsables()
    loadCatBrokers()
    generaReporte()
  }, [])

  useEffect(() => {
    mCatDestinos.filter((a) => {
      if (a.id == IDDestino) {
        setEntregar(a.direccion)
        return a
      }
    })
  }, [IDDestino])

  useEffect(() => {
    if (Cliente.includes('SELECCIONE')) setData(DataOriginal)
    else
      setData(
        DataOriginal.filter(function (row: DTO1868ReporteFactura) {
          return row.cliente === Cliente
        })
      )
  }, [Cliente])

  useEffect(() => {
    if (IdDODAFile > 0 && !ModalFileManager) {
      updateDODADoDATE(IdDODAFile)
    }
  }, [ModalFileManager])

  const changeDestino = (value: string) => {
    setIDDestino(parseInt(value))
    var result = mCatDestinos.filter((row) => row.id === parseInt(value))
    setEntregar(result[0] ? result[0].direccion : '')
  }

  const changeResponsable = (e: any) => {
    setIDResponsable(parseInt(e.target.value))
    var result = mCatResponsables.filter(
      (row) => row.id === parseInt(e.target.value)
    )
    setResponsable(result[0] ? result[0].responsable : '')
  }

  const changeToggle = (id: number) => {
    dispatch(toggleRow(id))
  }

  const Between = (x: number, min: number, max: number): boolean => {
    return x >= min && x <= max
  }

  const tienePendientes = (row: DTO1868ReporteFactura) => {
    let pendiente = false
    if (row.idpdf === 0) return true
    if ((row.sello ? row.sello : 0) === 0) return true
    if ((row.placas ? row.placas : 0) === 0) return true
    let detail = mDataFacturaDetalle.filter((d) => {
      if (d.idFactura === row.id) {
        return d
      }
    })
    let detailComplete = mDataFacturaDetalle.filter((d) => {
      if (d.idFactura === row.id && d.empaqueDescripcion.length > 0) {
        return d
      }
    })
    if (detail.length !== detailComplete.length) return true
    return pendiente
  }

  const ComparaFechas = (fechaSubida: string): boolean => {
    var FechaSubida: Date = new Date(fechaSubida.substring(0, 10))
    var Hoy: Date = new Date()
    Hoy.setHours(0, 0, 0, 0)
    const msBetweenDates = Math.abs(FechaSubida.getTime() - Hoy.getTime())
    const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000)
    if (daysBetweenDates > 0) return true
    return false
  }

  const faltaInfoTransportista = (row: DTO1868ReporteFactura) => {
    let pendiente = false
    if (ComparaFechas(row.fRegistro)) {
      if ((row.scac ? row.scac : 0) === 0) return true
      if ((row.caat ? row.caat : 0) === 0) return true
    }
    return false
  }

  const deleteInvoice = () => {
    FService.DeleteInvoice(ID)
      .then((response) => {
        generaReporte()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const doesDODAExists = (ID: number) => {
    const TieneArchivo = new Promise<number>((resolve, reject) => {
      FileManagerDS.getFileInfoFromProcess(ID, 23)
        .then((response) => {
          setIdDODAFile(response.data.nombreArchivo ? response.data.id : 0)
          resolve(response.data.nombreArchivo.length > 0 ? response.data.id : 0)
        })
        .catch((e: Error) => {
          resolve(0)
        })
    })

    TieneArchivo.then(function (IdDODA) {
      updateReferenciaRemesa(ID)
      if (IdDODA === 0) setModalFileManager(false)
      else {
        setModalFileManager(!(IdDODA > 0 && Referencia.length > 0))
      }
    }).catch(function (error) {
      setModalFileManager(true)
    })
  }

  const popover = (msg: string, leyend: string) => (
    <Popover id='popover-basic'>
      <Popover.Header as='h3'>{leyend}</Popover.Header>
      <Popover.Body>{msg}</Popover.Body>
    </Popover>
  )

  const loadCatBrokers = () => {
    CatBrokerDS.Get()
      .then((response) => {
        setCatBrokers(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const WhoIsBroker = (id: number): string => {
    if (id > 1) {
      let x = CatBrokers.filter((a) => {
        if (a.id == id) {
          return a.broker
        }
      })
      return x[0].broker
    }
    return 'GEMCO'
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <div className='row'>
            <div className='col-md-1'>
              <Form.Control
                defaultValue={Inicio}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setInicio(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-1'>
              <Form.Control
                defaultValue={Fin}
                type='date'
                name='Fin'
                placeholder='Fin'
                title='Fin'
                alt='Fin'
                onChange={(e) => setFin(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-2'>
              <Form.Control
                as='select'
                onChange={(e) => {
                  setCliente(e.target.value)
                }}
                className='form-select form-select-sm'
              >
                {Clientes
                  ? Clientes.map((a) => {
                      return (
                        <option value={a.cliente} key={a.cliente}>
                          {a.cliente}
                        </option>
                      )
                    })
                  : null}
              </Form.Control>
            </div>
            <div className='col-md-3'>
              <Form.Control
                type='text'
                size='sm'
                placeholder='Search...'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </div>
            <div className='col-md-1 right-label'>
              <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  generaReporte()
                }}
              >
                <BsSearch />
                Buscar
              </Button>
            </div>
            <div className='col'>
              <Button
                size='sm'
                variant='success'
                onClick={() => {
                  downloadExcel()
                }}
              >
                <BsFileEarmarkExcel />
                &nbsp; Descarga
              </Button>
            </div>
            <div className='col'>
              {Between(parseInt(UserType), 1, 3) ? (
                <Button
                  size='sm'
                  variant='uploadExcel'
                  style={{ textAlign: 'left' }}
                  onClick={() => {
                    uploadXML()
                  }}
                >
                  <IconContext.Provider
                    value={{ color: '#A7DDD1', size: '15px' }}
                  >
                    XML <BsCloudArrowUpFill />
                  </IconContext.Provider>
                </Button>
              ) : (
                ''
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {Between(parseInt(UserType), 1, 3) ? (
                <Button
                  size='sm'
                  variant='uploadPDF'
                  style={{ textAlign: 'right' }}
                  onClick={() => {
                    uploadPDF()
                  }}
                >
                  <IconContext.Provider
                    value={{ color: '#F8F686', size: '15px' }}
                  >
                    PDF <BsCloudArrowUpFill />
                  </IconContext.Provider>{' '}
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      <br />
      <div className='MDcontainer'>
        <Table className='MDTable' hover>
          <thead style={{ position: 'sticky', top: '0' }}>
            <tr>
              <th style={{ width: '50px', textAlign: 'center' }}>PDF</th>
              <th style={{ width: '50px', textAlign: 'center' }}>XML</th>
              <th style={{ width: '50px', textAlign: 'center' }}>DODA</th>
              <th style={{ width: '50px', textAlign: 'center' }}>Prefile</th>
              <th style={{ width: '50px', textAlign: 'center' }}>BL</th>
              <th style={{ width: '50px', textAlign: 'center' }}>HAZMAT</th>
              <th style={{ width: '80px', textAlign: 'center' }}>VIG. DODA</th>
              <th style={{ width: '40px', textAlign: 'center' }}>FCP</th>
              <th style={{ width: '40px', textAlign: 'center' }}>PL</th>
              <th style={{ width: '40px', textAlign: 'center' }}>InBond</th>
              <th style={{ width: '40px', textAlign: 'center' }}></th>
              <th style={{ width: '100px', textAlign: 'center' }}>
                Fecha factura
              </th>
              <th style={{ width: '100px' }}>Invoice #</th>
              <th style={{ width: '150px' }}>No Caja</th>
              <th style={{ width: '100px' }}>Fec cruce</th>
              <th style={{ width: '270px' }}>Cliente</th>
              <th style={{ width: '120px' }}>Pedido cliente</th>
              <th style={{ width: '50px' }}>Bultos</th>
              <th style={{ width: '50px' }}>Edita</th>
              <th style={{ width: '100px' }}>Broker</th>
              <th style={{ width: '270px' }}>Transportista</th>
              <th style={{ width: '270px' }}>Responsable cruce</th>
              <th style={{ width: '100px' }}>SCAC</th>
              <th style={{ width: '100px' }}>CAAT</th>
              <th style={{ width: '150px' }}>Sello</th>
              <th style={{ width: '150px' }}>Placas</th>
              <th style={{ width: '150px' }}>Carga</th>
              <th style={{ width: '100px' }}>Tipo pedimento</th>
              <th style={{ width: '550px' }}>Entregar en</th>
              <th style={{ width: '150px' }}>Aduana</th>
            </tr>
          </thead>
          <tbody>
            {Data
              ? Data.map((MasterData) => {
                  return (
                    <>
                      <tr
                        key={MasterData.id}
                        onClick={() => {
                          changeToggle(MasterData.id)
                        }}
                        style={{
                          cursor: 'pointer',
                          backgroundColor:
                            MasterData.urgente === 1 ? '#F9FC87' : '#FFFFFF',
                        }}
                      >
                        <td title={'Referencia: ' + MasterData.folioReferencia}>
                          {MasterData.idpdf > 0 ? (
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                showPDF(MasterData.idpdf)
                              }}
                            >
                              <IconContext.Provider
                                value={{ color: 'green', size: '17px' }}
                              >
                                <BsCheckCircleFill />
                              </IconContext.Provider>
                            </div>
                          ) : (
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                            >
                              <IconContext.Provider
                                value={{ color: 'red', size: '17px' }}
                              >
                                <BsXCircleFill />
                              </IconContext.Provider>
                            </div>
                          )}
                        </td>
                        <td>
                          <div
                            style={{ textAlign: 'center' }}
                            onClick={() => {
                              showXML(MasterData.idxml, MasterData.invoice)
                            }}
                          >
                            <IconContext.Provider
                              value={{ color: 'green', size: '17px' }}
                            >
                              <BsCheckCircleFill />
                            </IconContext.Provider>
                          </div>
                        </td>
                        <td>
                          {
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                setID(MasterData.id)
                                setProceso(23)
                                setReferencia(MasterData.referencia)
                                setRemesa(MasterData.remesa)
                                setPedimentoLargo(
                                  MasterData.pedimentoLargo
                                    ? MasterData.pedimentoLargo
                                    : ''
                                )
                                setModalFileManager(true)
                              }}
                            >
                              {MasterData.idDoda > 0 ? (
                                <IconContext.Provider
                                  value={{ color: 'green', size: '17px' }}
                                >
                                  <BsCheckCircleFill />
                                </IconContext.Provider>
                              ) : (
                                <IconContext.Provider
                                  value={{ color: 'red', size: '17px' }}
                                >
                                  <BsXCircleFill />
                                </IconContext.Provider>
                              )}
                            </div>
                          }
                        </td>
                        <td>
                          {
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                setID(MasterData.id)
                                setProceso(24)
                                setModalFileManager(true)
                              }}
                            >
                              {MasterData.idPrefile > 0 ? (
                                <IconContext.Provider
                                  value={{ color: 'green', size: '17px' }}
                                >
                                  <BsCheckCircleFill />
                                </IconContext.Provider>
                              ) : (
                                <IconContext.Provider
                                  value={{ color: 'red', size: '17px' }}
                                >
                                  <BsXCircleFill />
                                </IconContext.Provider>
                              )}
                            </div>
                          }
                        </td>
                        <td>
                          {
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                setID(MasterData.id)
                                setProceso(29)
                                setModalFileManager(true)
                              }}
                            >
                              {MasterData.idbl > 0 ? (
                                <IconContext.Provider
                                  value={{ color: 'green', size: '17px' }}
                                >
                                  <BsCheckCircleFill />
                                </IconContext.Provider>
                              ) : (
                                <IconContext.Provider
                                  value={{ color: 'red', size: '17px' }}
                                >
                                  <BsXCircleFill />
                                </IconContext.Provider>
                              )}
                            </div>
                          }
                        </td>
                        <td>
                          {
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                setID(MasterData.id)
                                setProceso(31)
                                setModalFileManager(true)
                              }}
                            >
                              {MasterData.idHazMat > 0 ? (
                                <IconContext.Provider
                                  value={{ color: 'green', size: '17px' }}
                                >
                                  <BsCheckCircleFill />
                                </IconContext.Provider>
                              ) : (
                                <IconContext.Provider
                                  value={{ color: 'red', size: '17px' }}
                                >
                                  <BsXCircleFill />
                                </IconContext.Provider>
                              )}
                            </div>
                          }
                        </td>
                        <td>
                          {MasterData.vigenciaDODA
                            ? MasterData.vigenciaDODA.substring(0, 10)
                            : ''}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <div>
                            {MasterData.folioCartaPorte ? (
                              <OverlayTrigger
                                trigger='click'
                                placement='right'
                                overlay={popover(
                                  MasterData.folioCartaPorte,
                                  'Folio carta porte'
                                )}
                              >
                                <div
                                  style={{
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <IconContext.Provider
                                    value={{ color: 'green', size: '17px' }}
                                  >
                                    <BsCheckCircleFill />
                                  </IconContext.Provider>
                                </div>
                              </OverlayTrigger>
                            ) : (
                              <IconContext.Provider
                                value={{ color: 'red', size: '17px' }}
                              >
                                <BsXCircleFill />
                              </IconContext.Provider>
                            )}
                          </div>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <div>
                            {MasterData.pedimentoLargo ? (
                              <OverlayTrigger
                                trigger='click'
                                placement='right'
                                overlay={popover(
                                  MasterData.pedimentoLargo,
                                  'Pedimento'
                                )}
                              >
                                <div
                                  style={{
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <IconContext.Provider
                                    value={{ color: 'green', size: '17px' }}
                                  >
                                    <BsCheckCircleFill />
                                  </IconContext.Provider>
                                </div>
                              </OverlayTrigger>
                            ) : (
                              <IconContext.Provider
                                value={{ color: 'red', size: '17px' }}
                              >
                                <BsXCircleFill />
                              </IconContext.Provider>
                            )}
                          </div>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <ReactToggleSliderSwitch
                            label={''}
                            checked={MasterData.inBond}
                            onChange={() => {}}
                            onColor='#48db0d'
                            offColor='#d3d3d3'
                            checkedIcon={true}
                            uncheckedIcon={true}
                            height={20}
                            width={60}
                            switchBgClassName='custom-bg-class'
                            switchHandleClassName='custom-handle-class'
                            labelStyle={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              color: InBond ? '#237404' : '#550a04',
                            }}
                          />
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          {tienePendientes(MasterData) ? (
                            <Stack>
                              <Badge pill bg='danger'>
                                <IconContext.Provider
                                  value={{ color: 'yellow', size: '15px' }}
                                >
                                  <BsExclamationTriangleFill />
                                </IconContext.Provider>
                              </Badge>
                            </Stack>
                          ) : (
                            ''
                          )}
                          {faltaInfoTransportista(MasterData) ? (
                            <Stack>
                              <Badge
                                pill
                                bg='warning'
                                style={{ color: '#000000' }}
                              >
                                SCAC / CAAT
                              </Badge>
                            </Stack>
                          ) : (
                            ''
                          )}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {MasterData.fechaFactura
                            ? MasterData.fechaFactura.substring(0, 10)
                            : ''}
                        </td>
                        <td style={{}}>
                          {MasterData.invoice}
                          <span
                            style={{
                              visibility:
                                MasterData.scac && MasterData.caat
                                  ? 'hidden'
                                  : 'visible',
                            }}
                            onClick={() => {
                              if (MasterData.scac && MasterData.caat) {
                                setHeader('Error')
                                setMsg(
                                  'No se puede borrar una factur que tiene asignado un SCAC y CAAT'
                                )
                                setShowMsg(true)
                                return
                              } else {
                                setID(MasterData.id)
                                setInvoice(MasterData.invoice)
                                setModalDeleteMaster(true)
                              }
                            }}
                          >
                            <IconContext.Provider
                              value={{ color: 'red', size: '10px' }}
                            >
                              <BsXCircleFill />
                            </IconContext.Provider>
                          </span>
                        </td>
                        <td style={{}}>{MasterData.ruta}</td>
                        <td style={{}}>
                          {MasterData.fechaCruce
                            ? MasterData.fechaCruce.substring(0, 10)
                            : ''}
                        </td>
                        <td style={{}}>{MasterData.cliente}</td>
                        <td style={{}}>{MasterData.pedidoCliente}</td>
                        <td style={{}}>{MasterData.pallets}</td>
                        <td>
                          <Button
                            size='sm'
                            variant='light'
                            style={{
                              textAlign: 'right',
                              visibility: Between(parseInt(UserType), 1, 3)
                                ? 'visible'
                                : 'hidden',
                            }}
                            onClick={() => {
                              EditMaster(MasterData)
                            }}
                          >
                            <IconContext.Provider
                              value={{ color: 'blue', size: '15px' }}
                            >
                              <BsFillPencilFill />
                            </IconContext.Provider>
                          </Button>
                        </td>
                        <td style={{}}>{WhoIsBroker(MasterData.idBroker)}</td>
                        <td style={{}}>{MasterData.transportista}</td>
                        <td style={{}}>{MasterData.responsableCruce}</td>
                        <td style={{}}>{MasterData.scac}</td>
                        <td style={{}}>{MasterData.caat}</td>
                        <td>{MasterData.sello}</td>
                        <td>{MasterData.placas}</td>
                        <td>{MasterData.carga}</td>
                        <td>{MasterData.tipoPedimento}</td>
                        <td>{MasterData.entregar}</td>
                        <td>{MasterData.aduana}</td>
                      </tr>
                      <tr style={{ height: '0px' }}>
                        <th
                          colSpan={2}
                          style={{ backgroundColor: '#F8F9FE', height: '0px' }}
                        ></th>
                        <th
                          colSpan={14}
                          style={{ backgroundColor: '#F8F9FE', height: '0px' }}
                        >
                          <Rpt1868FacturasDetalle
                            DataMaster={MasterData}
                            key={MasterData.id}
                          />
                        </th>
                      </tr>
                    </>
                  )
                })
              : null}
          </tbody>
        </Table>
      </div>
      <Row>
        <Col xs={11}></Col>
        <Col style={{ fontWeight: 'bold' }}>Total: {Data.length}</Col>
      </Row>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />

      <Modal
        show={modalFileManagerXML}
        onHide={() => setModalFileManagerXML(false)}
        size='lg'
      >
        <Modal.Body>
          <FileManager
            width={750}
            height={200}
            IDProcess={7}
            IdFile={CurrentTime2Number}
            IDUser={UserId}
            FileName={''}
            canDelete={false}
            FileType={['xml', 'XML']}
            Leyenda={
              'Estimado cliente: ZINC INTERNACIONAL, favor de seleccionar el archivo xml, arrastrelo hasta aqui y sueltelo para agregar la informacion a este reporte...'
            }
            onAppendFM={function (idFile: number): void {
              uploadFile(idFile, 7)
            }}
            onDeleteFM={function (IDMaster: number, Proceso: number): void {}}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={11}>&nbsp;</Col>
            <Col xs={1}>
              <Button
                variant='secondary'
                onClick={() => setModalFileManagerXML(false)}
                size='sm'
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalFileManagerPDF}
        onHide={() => setModalFileManagerPDF(false)}
        size='lg'
      >
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <FileManager
                width={750}
                height={200}
                IDProcess={8}
                IdFile={CurrentTime2Number}
                IDUser={UserId}
                FileName={''}
                canDelete={false}
                FileType={['pdf', 'PDF']}
                Leyenda={
                  'Estimado cliente: ZINC INTERNACIONAL, favor de seleccionar el archivo PDF, arrastrelo hasta aqui y sueltelo para agregar la informacion a este reporte...'
                }
                onAppendFM={function (idFile: number): void {
                  uploadFile(idFile, 8)
                }}
                onDeleteFM={function (
                  IDMaster: number,
                  Proceso: number
                ): void {}}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={11}>&nbsp;</Col>
            <Col xs={1}>
              <Button
                variant='secondary'
                onClick={() => setModalFileManagerPDF(false)}
                size='sm'
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalEditMaster}
        onHide={() => setModalEditMaster(false)}
        dialogClassName='modal-50w'
      >
        <Modal.Body>
          <Card className='labelSize13px'>
            <Card.Body>
              <Card>
                <Row>
                  <Col xs={12}>
                    <Alert variant='primary' style={{ textAlign: 'center' }}>
                      <h6>
                        Favor de proporcionar la siguiente informacion para
                        complementar el reporte [{Invoice}]
                        <br />
                      </h6>
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={1}
                    style={{ paddingLeft: '15px', paddingTop: '5px' }}
                  >
                    Sello
                  </Col>
                  <Col xs={4}>
                    <Form.Control
                      type='text'
                      id='Sellos'
                      value={Sellos}
                      size='sm'
                      onChange={(e) => {
                        setSellos(e.target.value)
                      }}
                    />
                  </Col>
                  <Col xs={1} style={{ paddingTop: '5px' }}>
                    Placa
                  </Col>
                  <Col xs={3}>
                    <Form.Control
                      type='text'
                      id='Placas'
                      value={Placas}
                      size='sm'
                      onChange={(e) => {
                        setPlacas(e.target.value)
                      }}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      as='select'
                      value={Urgente}
                      onChange={(e) => {
                        setUrgente(parseInt(e.target.value))
                      }}
                      className='form-select form-select-sm'
                    >
                      <option value='0'>No Urgente</option>
                      <option value='1'>URGENTE</option>
                    </Form.Control>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px' }}>
                  <Col xs={1}>
                    <div
                      style={{ cursor: 'pointer', paddingLeft: '10px' }}
                      onClick={() => {
                        loadCatalog(1)
                      }}
                      title='Catalogo de reponsables'
                    >
                      <IconContext.Provider
                        value={{ color: 'blue', size: '25px' }}
                      >
                        <FaPeopleArrows />
                      </IconContext.Provider>
                    </div>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      as='select'
                      value={IDResponsable}
                      onChange={(e) => {
                        changeResponsable(e)
                      }}
                      className='form-select form-select-sm'
                    >
                      <option value='0'>Responsables...</option>
                      {mCatResponsables
                        ? mCatResponsables
                            .filter((item) => item.padre == 0)
                            .map((a) => {
                              return (
                                <option value={a.id} key={a.id}>
                                  {a.responsable}
                                </option>
                              )
                            })
                        : null}
                    </Form.Control>
                  </Col>
                  <Col xs={1} style={{ paddingTop: '5px' }}>
                    Bulto
                  </Col>
                  <Col xs={2}>
                    <Form.Control
                      type='number'
                      id='Pallets'
                      value={Pallets}
                      size='sm'
                      onChange={(e) => {
                        setPallets(parseInt(e.target.value))
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px' }}>
                  <Col
                    xs={1}
                    style={{ paddingLeft: '10px', paddingTop: '5px' }}
                  >
                    Trans
                  </Col>
                  <Col xs={7}>
                    <Form.Control
                      as='select'
                      value={IDTransfer}
                      onChange={(e) => {
                        setIDTransfer(parseInt(e.currentTarget.value))
                      }}
                      className='form-select form-select-sm'
                    >
                      <option value='0'>Catalogo de transfers</option>
                      {mCatResponsables
                        ? mCatResponsables
                            .filter((item) => item.padre < 0)
                            .map((a) => {
                              return (
                                <option value={a.id} key={a.id}>
                                  {a.responsable}
                                </option>
                              )
                            })
                        : null}
                    </Form.Control>
                  </Col>
                  <Col style={{ paddingRight: '10px', paddingTop: '5px' }}>
                    Brk
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      size='sm'
                      onChange={(e: any): void => {
                        setIdBroker(e.target.value)
                      }}
                      value={IdBroker}
                    >
                      {CatBrokers &&
                        CatBrokers.map((item) => {
                          return <option value={item.id}>{item.broker}</option>
                        })}
                    </Form.Select>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px' }}>
                  <Col xs={1}>
                    <div
                      style={{ cursor: 'pointer', paddingLeft: '10px' }}
                      onClick={() => {
                        loadCatalog(2)
                      }}
                      title='Catalogo de destinos'
                    >
                      <IconContext.Provider
                        value={{ color: 'orange', size: '25px' }}
                      >
                        <FaWarehouse />
                      </IconContext.Provider>
                      <br />
                      <br />
                      <div style={{ backgroundColor: 'black', height: '28px' }}>
                        <IconContext.Provider
                          value={{ color: 'yellow', size: '25px' }}
                        >
                          <BsSignMergeLeft />
                        </IconContext.Provider>
                      </div>
                    </div>
                  </Col>
                  <Col xs={4} style={{ paddingLeft: '10px' }} size='sm'>
                    <Form.Control
                      as='select'
                      value={IDDestino}
                      onChange={(e) => {
                        changeDestino(e.target.value)
                      }}
                      className='form-select form-select-sm'
                    >
                      <option value='0'>DESTINOS...</option>
                      {mCatDestinos
                        ? mCatDestinos.map((a) => {
                            return (
                              <option value={a.id} key={a.id}>
                                {a.lugar}
                              </option>
                            )
                          })
                        : null}
                    </Form.Control>
                    <br />
                    <Form.Control
                      as='select'
                      value={TipoViaje}
                      onChange={(e) => {
                        setTipoViaje(parseInt(e.target.value))
                      }}
                      className='form-select form-select-sm'
                    >
                      <option value='1'>DIRECTO</option>
                      <option value='2'>TRANSBORDO</option>
                    </Form.Control>
                    <br />
                    <span
                      title='Para cambiar el estatus, seleccione el switch'
                      style={{ cursor: 'pointer' }}
                    >
                      <ReactToggleSliderSwitch
                        label={LblInBond}
                        checked={InBond}
                        onChange={handleChange}
                        onColor='#48db0d'
                        offColor='#d3d3d3'
                        checkedIcon={true}
                        uncheckedIcon={true}
                        height={25}
                        width={70}
                        switchBgClassName='custom-bg-class'
                        switchHandleClassName='custom-handle-class'
                        labelStyle={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          color: InBond ? '#237404' : '#550a04',
                        }}
                      />
                    </span>
                  </Col>
                  <Col xs={7}>
                    <Form.Control
                      rows={6}
                      as='textarea'
                      id='Entregar'
                      disabled={true}
                      value={Entregar}
                      size='sm'
                      onChange={(e) => {
                        setEntregar(e.target.value)
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={1}>&nbsp;</Col>
            <Col xs={2}>
              <Button variant='primary' onClick={() => saveMaster()} size='sm'>
                Guardar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={ModalCatalogos}
        onHide={() => setModalCatalogos(false)}
        dialogClassName={Catalogo === 1 ? 'modal-50w' : 'modal-90w'}
      >
        <Modal.Body>
          <Card className='labelSize13px'>
            <Card.Body>
              <Card>
                <Row>
                  <Col xs={12}></Col>
                </Row>
                <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  {Catalogo === 1 ? <Cat1868Responsables /> : null}
                  {Catalogo === 2 ? <Cat1868Destinos /> : null}
                </Row>
              </Card>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalDeleteMaster}
        onHide={() => setModalDeleteMaster(false)}
        size='lg'
      >
        <Modal.Body>
          <Card className='labelSize13px'>
            <Card.Body>
              <Card>
                <Row>
                  <Col xs={12}>
                    <Alert variant={'warning'} style={{ textAlign: 'center' }}>
                      <h4>¿Esta seguro de eliminar esta factura: {Invoice}?</h4>
                    </Alert>
                  </Col>
                </Row>
                <Row
                  style={{ paddingTop: '10px', paddingBottom: '10px' }}
                ></Row>
                <Row style={{ paddingBottom: '10px' }}>
                  <Col></Col>
                  <Col xs={5}>
                    <Button
                      as='input'
                      type='button'
                      variant='danger'
                      value='Elimina'
                      onClick={() => deleteInvoice()}
                    />
                  </Col>
                  <Col xs={5} style={{ textAlign: 'right' }}>
                    <Button
                      as='input'
                      type='button'
                      variant='secondary'
                      value='  Salir  '
                      onClick={() => setModalDeleteMaster(false)}
                    />
                  </Col>
                  <Col></Col>
                </Row>
              </Card>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>

      <Modal
        show={ModalFileManager}
        /*  onHide={() => setModalFileManager(false)} */
        size='lg'
      >
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <FileManager
                width={750}
                height={200}
                IDProcess={Proceso}
                IdFile={ID}
                IDUser={UserId}
                FileName={''}
                canDelete={true}
                FileType={['pdf', 'PDF']}
                Leyenda={`Estimado colaborador, favor de seleccionar el archivo ${
                  Proceso === 23
                    ? 'DODA'
                    : Proceso === 24
                    ? 'Prefile'
                    : Proceso === 29
                    ? 'BL'
                    : 'HAZMAT'
                }, arrastrelo hasta aqui y sueltelo para agregar la informacion a este reporte...`}
                onAppendFM={function (idFile: number): void {
                  setIdDODAFile(Proceso === 23 ? idFile : 0)
                  uploadFileByProcess(idFile, Proceso)
                }}
                onDeleteFM={function (IDFile: number, Process: number): void {
                  UnRelateFileByProcess(IDFile, Proceso)
                }}
              />
            </Col>
          </Row>

          <Row
            style={{
              display: 'flex',
              visibility: Proceso === 23 ? 'visible' : 'hidden',
              height: Proceso === 23 ? '' : '0px',
            }}
          >
            <Col></Col>
            <Col>Referencia:</Col>
            <Col xs={2}>
              <Form.Control
                defaultValue={Referencia}
                type='text'
                name='Referencia'
                placeholder='Referencia'
                title='Referencia'
                alt='Referencia'
                style={{ borderColor: 'red' }}
                onChange={(e) => setReferencia(e.target.value)}
                size='sm'
              />
            </Col>
            <Col></Col>
            <Col>Remesa:</Col>
            <Col xs={2}>
              <Form.Control
                defaultValue={Remesa}
                type='text'
                name='Remesa'
                placeholder='Remesa'
                title='Remesa'
                alt='Remesa'
                onChange={(e) => setRemesa(e.target.value)}
                size='sm'
              />
            </Col>
            <Col></Col>
            <Col>Vigencia:</Col>
            <Col xs={2}>
              <Form.Control
                defaultValue={FecVigenciaDODA}
                type='date'
                name='FecVigenciaDODA'
                placeholder='FecVigenciaDODA'
                title='FecVigenciaDODA'
                alt='FecVigenciaDODA'
                onChange={(e) => setFecVigenciaDODA(e.target.value)}
                size='sm'
              />
            </Col>
            <Col></Col>
          </Row>
          <Row
            style={{
              paddingTop: '10px',
              visibility: Proceso === 23 ? 'visible' : 'hidden',
              height: Proceso === 23 ? '' : '0px',
            }}
          >
            <Col xs={2}></Col>
            <Col xs={8}>
              <Stack direction='horizontal'>
                <Badge bg='warning' text='dark'>
                  Este tipo de documento requiere que obligatoriamente que le
                  asocie una referencia
                </Badge>
              </Stack>
            </Col>
          </Row>
          <div style={{ height: Proceso === 23 ? '' : '0px' }}>
            <hr />
            <Row style={{ visibility: Proceso === 23 ? 'visible' : 'hidden' }}>
              <Col></Col>
              <Col>
                <Badge
                  bg='danger'
                  style={{
                    fontSize: '17px',
                    fontFamily: 'Arial',
                    paddingTop: '14px',
                  }}
                >
                  Pedimento largo (Aduana - Patente - Pedimento):
                </Badge>
              </Col>
              <Col xs={3}>
                <FormControl
                  type='text'
                  as={InputMask}
                  mask='***-****-*******'
                  value={PedimentoLargo}
                  alwaysShowMask={true}
                  onChange={(e) => {
                    setPedimentoLargo(e.target.value)
                  }}
                />
              </Col>
              <Col></Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={11}>&nbsp;</Col>
            <Col xs={1}>
              <Button
                variant='secondary'
                onClick={() => {
                  Proceso !== 23
                    ? setModalFileManager(false)
                    : doesDODAExists(ID)
                }}
                size='sm'
                style={{
                  visibility:
                    Proceso !== 23 ||
                    (IdDODAFile > 0 && Referencia) ||
                    IdDODAFile === 0
                      ? 'visible'
                      : 'hidden',
                }}
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
